const defaultConfig = {
    "hood-base-color": "black",
    "crown-color": "black",
    "brow-color": "white",
    "bridge-color": "white",
    "nose-color": "black",
    "muzzle-color": "white",
    "muzzle-lip-color": "white",
    "chin-color": "black",
    "ear-a-color": "white",
    "ear-b-color": "black",
    "ear-c-color": "black",
    "muzzle-a-color": "white",
    "muzzle-b-color": "white",
    "muzzle-button": "black",
    "bridge-button": "black",
    "muzzle-style": "default", // ["default", "fur", "added-default", "added-fur"]
    "muzzle-assoicate": "default", // ["default", "mustache", "fur"]
    "eyebrow-style": "default", // ["default", "dou", "frame"]
    "glass-hole": false,
    "ear-hole": false,
    "black-type-light": true,
    "hood-size": "L"
};

const colorItems = [
    "hood-base-color",
    "crown-color",
    "brow-color",
    "bridge-color",
    "nose-color",
    "muzzle-color",
    "muzzle-lip-color",
    "chin-color",
    "ear-a-color",
    "ear-b-color",
    "ear-c-color",
    "muzzle-a-color",
    "muzzle-b-color",
    "muzzle-button",
    "bridge-button"
];

const styleItems = [
    "muzzle-style",
    "muzzle-assoicate",
    "eyebrow-style"
];

const checkItems = [
    "glass-hole",
    "ear-hole",
    "black-type-light"
];

const revertCheckItems = [
    "black-type-light",
    "ear-hole",
    "glass-hole"
];

const colorList = [
    "red",
    "orange",
    "yellow",
    "green",
    "tiffany",
    "light-blue",
    "blue",
    "brown",
    "pupple",
    "pink",
    "white",
    "gray",
    "black",
    "texture-black",
    "texture-1",
    "texture-2",
    "metal"
];

const styleList = [
    "default",
    "fur",
    "added-default",
    "added-fur",
    "mustache",
    "dou",
    "frame"
];

const sizeList = [
    "XL",
    "L",
    "M"
]

function encodeColor(config) {
    let colorStr = ''
    colorItems.forEach((i) => {
        let r = colorList.indexOf(config[i]);
        if (r < 0) {
            r = 12;
            throw new Error('Unknown Color: ' + i + ' ' + config[i]);
        }
        colorStr += r.toString(32);
    })
    return colorStr;
}

function encodeStyle(config) {
    let styleStr = ''
    styleItems.forEach((i) => {
        let r = styleList.indexOf(config[i]);
        if (r < 0) {
            r = 0;
            throw new Error('Unknown Style: ' + i + ' '  + config[i]);
        }
        styleStr += r.toString(32);
    })
    return styleStr;
}

function encodeCheck(config) {
    let check = 0
    checkItems.forEach((i) => {
        check = check * 2 + (config[i] ? 1 : 0);
    })
    return check.toString(32);
}

function encode(config) {
    try {
        return (encodeColor(config) + "_" + encodeStyle(config) + "_" + encodeCheck(config) + "_" + ('hood-size' in config ? config['hood-size']: "L")).toUpperCase();
    } catch (error){
        console.log('错误', error);
        // alert('未知错误！');
    }
    
}

function decodeColor(str) {
    if (str.length === colorItems.length - 1) {
        str = str + str[str.length - 1]
    }
    if (str.length !== colorItems.length) {
        return {}
    }
    let colorConfig = {}
    colorItems.forEach((k, i) => {
        const colorIndex = parseInt(str[i], 32);
        if (colorIndex < colorList.length) {
            colorConfig[k] = colorList[colorIndex];
        }
    })
    return colorConfig;
}

function decodeStyle(str) {
    if (str.length !== styleItems.length) {
        return {}
    }
    let styleConfig = {}
    styleItems.forEach((k, i) => {
        const styleIndex = parseInt(str[i], 32);
        if (styleIndex < styleList.length) {
            styleConfig[k] = styleList[styleIndex];
        }
    })
    return styleConfig;
}

function decodeCheck(str) {
    let check = parseInt(str, 32);
    let checkConfig = {}
    revertCheckItems.forEach((k) => {
        checkConfig[k] = check % 2 === 1 ? true : false
        check = Math.floor(check / 2)
    });
    return checkConfig;
}

function decode(str) {
    try {
        let [colorStr, styleStr, checkStr, sizeStr] = str.split('_')
        if (!sizeList.includes(sizeStr)) {
            sizeStr = "L"
        }

        const colorConfig = decodeColor(colorStr);
        const styleConfig = decodeStyle(styleStr);
        const checkConfig = decodeCheck(checkStr);
        if (!colorConfig || !styleConfig || !checkConfig) {
            return defaultConfig;
        }
        const sizeConfig = {"hood-size": sizeStr}
        return {...colorConfig, ...styleConfig, ...checkConfig, ...sizeConfig};
    } catch (error) {
        console.log('错误', error);
        return defaultConfig;
    }
}

module.exports = {
    encode,
    decode,
    colorList,
    defaultConfig
};
